import React from "react";
import "./loading.css";

const Loader = () => (
  <div className="loader">
    <svg className="circular-loader" viewBox="25 25 50 50">
      <circle className="loader-path" cx="50" cy="50" r="20" />
    </svg>
  </div>
);

const Loading = ({ isLoading }) => {
  return (
    isLoading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <Loader />
      </div>
    )
  );
};

export default Loading;
