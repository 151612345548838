import React from "react";
import { ReactComponent as Triangle } from "@/assets/images/triangle.svg";
import { ReactComponent as Money } from "@/assets/images/PricePlan.svg";
import { ReactComponent as Support } from "@/assets/images/support.svg";
import { ReactComponent as Group } from "@/assets/images/group.svg";
import { ReactComponent as Gift } from "@/assets/images/gift.svg";
import { ReactComponent as Diamond } from "@/assets/images/diamond.svg";
import { ReactComponent as NTF } from "@/assets/images/NTF.svg";
import SilverCard from "@/assets/images/card_green.png";

import Mint from "@/assets/images/mint.png";
import { CommonProps } from "../Subscription/Subcription";
import { FigureType } from "@/utils/constants/constants";

const Figure = ({
  onClick,
  type = FigureType.OLD_ACCOUNT,
  minted = 0,
}: CommonProps) => {
  return (
    <div>
      <div className="card__image_box">
        <div className="card__image">
          <img
            src={type === FigureType.OLD_ACCOUNT ? Mint : SilverCard}
            alt="NTF-min"
          />
        </div>
        <div>
          <h3 className="title">
            {type === FigureType.OLD_ACCOUNT
              ? "Cpc Membership Pass"
              : "Old Membership Card"}
          </h3>
          <div className="grid_info">
            <div className="info_box">
              <span className="green">Price</span>
              <span className="title small">
                {type === FigureType.OLD_ACCOUNT ? (
                  "$299"
                ) : (
                  <>
                    0.12 <span className="smaller">BNB</span>
                  </>
                )}
              </span>
            </div>
            <div className="info_box">
              <span className="green">Supply</span>
              <span className="title small">
                {type === FigureType.OLD_ACCOUNT ? "1000" : "1000"}
              </span>
            </div>
            <div className="info_box">
              <span className="green">Minted</span>
              <span className="title small">
                {type === FigureType.OLD_ACCOUNT ? "1/100" : `${minted}/1000`}
              </span>
            </div>
          </div>
          <div>
            {type === FigureType.OLD_ACCOUNT && (
              <>
                <p>
                  <Triangle />
                  Chỉ dành cho thành viên nhóm mindset, report
                </p>
                <p>
                  <Triangle />
                  Sử dụng app CPC 30 ngày miễn phí
                </p>
                <p>
                  <Triangle />
                  Voucher khi sử dụng dịch vụ của CPC Capital
                </p>
                <p>
                  <Triangle />
                  Airdrop trong tương lai
                </p>
              </>
            )}

            <button
              className={`multi_color_btn button ${
                type === FigureType.CPC_ACCOUNT ? "mt_30" : ""
              }`}
              onClick={onClick}
            >
              <NTF />
              Mint now
            </button>
          </div>
        </div>
      </div>
      <div className="card__grid">
        <div className="card">
          <span className="card__icon">
            <Support />
          </span>
          <div>
            <h5 className="card__header">
              {" "}
              {type === FigureType.OLD_ACCOUNT
                ? "30 days trial"
                : "Join Telegram private group CPC Capital"}
            </h5>
            <p className="">
              {type === FigureType.OLD_ACCOUNT
                ? "Holder được nhận hỗ trợ, tư vấn tài chính trực tiếp từ CPC Capital"
                : "NFT holder sẽ được support ở nhóm Telegram private theo thời hạn đã đăng kí report"}
            </p>
          </div>
        </div>
        <div className="card">
          <span className="card__icon">
            <Group />
          </span>
          <div>
            <h5 className="card__header">
              {type === FigureType.OLD_ACCOUNT
                ? "100 whales VIP Club"
                : "Giảm 36$ khi gia hạn report"}
            </h5>
            <p className="">
              {type === FigureType.OLD_ACCOUNT
                ? "Độc quyền truy cập vào nhóm members 100 whales, cập nhật thị trường, insight, kèo hold dài hạn, trade ngắn hạn, vv..."
                : "Giảm 36$ khi gia hạn report 1 năm"}
            </p>
          </div>
        </div>
        <div className="card">
          <span className="card__icon">
            {type === FigureType.OLD_ACCOUNT ? <Gift /> : <Support />}
          </span>
          <div>
            <h5 className="card__header">
              {type === FigureType.OLD_ACCOUNT
                ? "Voucher"
                : "Vé tham gia WL, IDO"}
            </h5>
            <p className="">
              {type === FigureType.OLD_ACCOUNT
                ? "Luôn nhận được ưu đãi không giới hạn dịch vụ từ CPC Capital"
                : "Holder được quyên truy cập vào WL, IDO các dự án đối tác của CPC Capital"}
            </p>
          </div>
        </div>
        <div className="card">
          <span className="card__icon">
            {type === FigureType.OLD_ACCOUNT ? <Money /> : <Gift />}
          </span>
          <div>
            <h5 className="card__header">
              {type === FigureType.OLD_ACCOUNT
                ? "Đổi 1:1 PFP NFTs VIP của CPC"
                : "Thời hạn sử dụng dịch vụ thêm 30 ngày"}
            </h5>
            <p className="">
              {type === FigureType.OLD_ACCOUNT
                ? "Mỗi 1 box sẽ được đổi lấy 1 NFT trong Collection CPC Genesis Pfp, với 100 NFTs độc nhất, được đầu tư về chất lượng art"
                : "Khi sử dụng NFT, holders sẽ được kích hoạt gia hạn dịch vụ ngay lập tức, tính năng này sẽ được sử dụng 1 lần duy nhất."}
            </p>
          </div>
        </div>
        <div className="card">
          <span className="card__icon">
            <Diamond />
          </span>
          <div>
            <h5 className="card__header">
              {type === FigureType.OLD_ACCOUNT
                ? "More benefits in future"
                : "Có cơ hội nhận airdrop trong tương lai"}
            </h5>
            <p className="">
              {type === FigureType.OLD_ACCOUNT
                ? "Với định hướng lâu dài, CPC không ngừng phát triển, và sẽ luôn đem lại các dịch vụ và quyền lợi đặc biệt cho holders."
                : "Cơ hội nhận airdrop các dự án được thực hiện bởi đội ngũ CPC trong tương lai"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Figure;
