export const NETWORKS = {
  '56' :  {
    chainName: 'Binance Smart Chain Mainnet',
    chainId: '0x38',
    nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  '97' :  {
    chainName: 'BNB Smart Chain Testnet',
    chainId: '0x61',
    nativeCurrency: { name: 'tBNB', decimals: 18, symbol: 'tBNB' },
    rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545/']
  },
  '80001' :  {
    chainName: 'Mumbai',
    chainId: '0x13881',
    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    rpcUrls: ['https://rpc.ankr.com/polygon_mumbai/']
  },
}