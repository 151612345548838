import React, { useMemo, useEffect, useCallback, useState } from "react";
import { ReactComponent as Logo } from "./assets/images/Logo.svg";
import Banner from "./assets/images/banner.png";
import Background from "./assets/images/background.png";
import "./App.scss";
import Figure from "./components/Figure/Figure";
import Subscription from "./components/Subscription/Subcription";
import Loading from "./components/Loading";
import { ReactComponent as Wallet } from "@/assets/images/Wallet.svg";
import { ReactComponent as NTF } from "@/assets/images/NTF.svg";
import { ReactComponent as Logout } from "@/assets/images/Log_out.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ethers } from "ethers";
import abi from "@/utils/constants/abi.json";
import {
  ACC_VALUE,
  ButtonType,
  FigureType,
  SlidePositionType,
} from "./utils/constants/constants";
import { NETWORKS } from "./utils/constants/networks";
import PopupModal from "./components/Modal/Modal";

function App() {
  const [active, setActive] = React.useState<SlidePositionType>(
    SlidePositionType.INIT
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [haveMetamask, sethaveMetamask] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [contract, setContract] = useState<any>();
  const [minted, setMinted] = useState<number>(0);
  const [network, setNetwork] = useState<any>();
  const [account, setAccount] = useState<any>(
    localStorage.getItem(ACC_VALUE) ?? ""
  );
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ID ?? "";
  const CHAIN_ID = process.env.REACT_APP_CHAIN_ID ?? "";
  const META_URL = process.env.REACT_APP_META_MARK_URL ?? "";
  const RPC_URL = process.env.REACT_APP_RPC_URL ?? "";

  const provider: any = useMemo(
    () =>
      window.ethereum != null
        ? new ethers.providers.Web3Provider(window.ethereum)
        : ethers.providers.getDefaultProvider(),
    []
  );

  const handleCheckNetwork = useCallback(async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    setNetwork(chainId);
    if (window.ethereum.networkVersion != CHAIN_ID) {
      // @ts-ignore
      const chainHex = NETWORKS[String(CHAIN_ID)].chainId;
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainHex }],
        });
        await handleConnectContract();
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        // @ts-ignore
        const chain = NETWORKS[CHAIN_ID];
        // @ts-ignore
        if (err.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [chain],
          });
          await handleConnectContract();
        }
      }
    } else {
      await handleConnectContract();
    }
  }, [NETWORKS, window]);

  const handleConnectContract = useCallback(async () => {
    const ntfContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      abi.abi,
      provider.getSigner()
    );
    setContract(ntfContract);
  }, [CONTRACT_ADDRESS, provider])

  useEffect(() => {
    const checkMetamaskAvailability = async () => {
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
    handleGetSupply();
    if (window.ethereum) {
      handleCheckNetwork();
      window.ethereum.on("chainChanged", async (newChain: any) => {
        setNetwork(newChain);
      });

      window.ethereum.on("accountsChanged", (acc: any) => {
        setAccount(acc[0]);
        setIsConnected(false);
        localStorage.setItem(ACC_VALUE, acc[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetSupply = useCallback(async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
      const contract = new ethers.Contract(CONTRACT_ADDRESS, abi.abi, provider);

      const lastTokenId = await contract.tokenIdTracker()
      const totalMinted = ethers.BigNumber.from(lastTokenId).toNumber()
      setMinted(totalMinted)
    } catch (e) {
      console.error(e)
    }
  }, [CONTRACT_ADDRESS, abi])

  const handleMetamask = useCallback(async () => {
    const { ethereum } = window;
    await handleCheckNetwork();

    setLoading(true);
  }, []);

  const connectWallet = useCallback(async () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    try {
      const { ethereum } = window;
      if (!ethereum) {
        if (isMobile) {
          const deepLink = CHAIN_ID == '56' ? 'https://metamask.app.link/dapp/https://cpccapital.io/' : 'https://metamask.app.link/dapp/https://testnet.cpccapital.io/'
          if (window.navigator.share) {
            const url = deepLink;
            window.navigator.share({ url });
          } else {
            window.location.href = deepLink;
          }
        } else {
          window.location.href = META_URL;
        }
      }
      await handleCheckNetwork();
      const account = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(account);
      localStorage.setItem(ACC_VALUE, account);
      setIsConnected(true);
      setLoading(false);
    } catch (error) {
      setIsConnected(false);
      setLoading(false);
    }
  }, [META_URL]);

  const buyToken = useCallback(async () => {
    if (!isConnected) {
      await connectWallet();
    }
    setLoading(true);
    // @ts-ignore
    const supportChain = NETWORKS[CHAIN_ID].chainId;
    if (network != supportChain) {
      await handleCheckNetwork();
    }

    if (!contract) {
      await handleConnectContract();
    }
    try {
      const TOTAL_MINT_NFT = process.env.REACT_APP_TOTAL_MINT_NFT;
      const AMOUNT = process.env.REACT_APP_AMOUNT ?? "0.12";
      const transaction = await contract.buy(TOTAL_MINT_NFT, {
        value: ethers.utils.parseUnits(AMOUNT, "ether"),
      });
      await transaction.wait();
      setLoading(false);
      setOpenModal(true);
      toast.success("Transaction success !");
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error("Transaction failure !");

      // @ts-ignore
      if (err.code == "NETWORK_ERROR") {
        // reload connect error
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    }
  }, [contract, handleMetamask]);

  const handleLogout = useCallback(() => {
    setShowLogout(false);
    setIsConnected(false);
    localStorage.removeItem(ACC_VALUE);
    setAccount(null);
  }, []);

  const renderButton = useCallback(
    (type: number) => {
      if (type === ButtonType.BUY) {
        if ((haveMetamask && isConnected) || localStorage.getItem(ACC_VALUE)) {
          return (
            <button className={`multi_color_btn button '}`} onClick={buyToken}>
              <NTF />
              Mint now
            </button>
          );
        } else {
          return (
            <button
              className={`multi_color_btn button '}`}
              onClick={connectWallet}
            >
              <NTF />
              Mint now
            </button>
          );
        }
      } else {
        if ((haveMetamask && isConnected) || localStorage.getItem(ACC_VALUE)) {
          return (
            <div className="button_wrapper">
              <button
                className={`multi_color_btn button text_clipped main_button`}
                onClick={() => {
                  setShowLogout(true);
                }}
              >
                <Wallet />
                {account}
              </button>
            </div>
          );
        } else {
          return (
            <button
              className={`multi_color_btn button  main_button`}
              onClick={connectWallet}
            >
              <Wallet />
              Connect wallet
            </button>
          );
        }
      }
    },
    [account, buyToken, connectWallet, haveMetamask, isConnected]
  );

  const handleClick = useCallback(() => {
    if (contract || localStorage.getItem(ACC_VALUE)) {
      buyToken();
    } else {
      connectWallet();
    }
  }, [buyToken, connectWallet, contract]);

  const renderView = useMemo(() => {
    switch (active) {
      case SlidePositionType.INIT:
        return (
          <figure>
            <Figure
              onClick={handleClick}
              type={FigureType.CPC_ACCOUNT}
              minted={minted}
            />
          </figure>
        );
      case SlidePositionType.CPC_ACCOUNT:
        return (
          <>
            <figure className="slide-in-left">
              <Figure
                onClick={handleClick}
                type={FigureType.CPC_ACCOUNT}
                minted={minted}
              />
            </figure>
            <figure className="slide-out">
              <Figure onClick={handleClick} />
            </figure>
          </>
        );
      case SlidePositionType.OLD_CPC_ACCOUNT:
        return (
          <>
            <figure className="slide-out-left">
              <Figure onClick={handleClick} type={FigureType.CPC_ACCOUNT} />
            </figure>
            <figure className="slide-in">
              <Figure onClick={handleClick} />
            </figure>
          </>
        );
      default:
        return (
          <figure className="slide-out-left">
            <Subscription onClick={buyToken} />
          </figure>
        );
    }
  }, [active, buyToken, handleClick, minted]);

  const contextClass = {
    success: "bg-blue-600",
    error: "bg-red-600",
    info: "bg-gray-600",
    warning: "bg-orange-400",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
  };

  return (
    <div className="App cpc_app">
      <header className="App-header">
        <div className="container">
          <div className="header_container">
            <Logo />
            <div className="header_button">
              {renderButton(ButtonType.CONNECT)}
              <button className="button main_button">
                <a href="https://app.cpccapital.io/">Launch App</a>
              </button>
            </div>
          </div>
        </div>
        {showLogout && (
          <div className="button_dropdown" onClick={handleLogout}>
            <Logout /> <span>Disconnect</span>
          </div>
        )}
      </header>
      <div
        className="backdrop"
        style={{ background: `url('${Background}') no-repeat 900px -50px` }}
      ></div>
      <div
        className="bottom backdrop"
        style={{ background: `url('${Background}') no-repeat 840px 134px` }}
      ></div>

      <main>
        <section className="container">
          <div className="banner">
            <img src={Banner} alt="NTF-min" />
          </div>
        </section>
        <section className="container">
          <div className="switch">
            <button
              className={`button switcher ${
                [
                  SlidePositionType.CPC_ACCOUNT,
                  SlidePositionType.INIT,
                ].includes(active)
                  ? "active"
                  : ""
              }`}
              onClick={() => setActive(SlidePositionType.CPC_ACCOUNT)}
            >
              Membership
            </button>
            {/*<button*/}
            {/*  className={`button switcher ${active === SlidePositionType.OLD_CPC_ACCOUNT ? "active" : ""}`}*/}
            {/*  onClick={() => setActive(SlidePositionType.OLD_CPC_ACCOUNT)}*/}
            {/*>*/}
            {/*  VIP Nfts*/}
            {/*</button>*/}
          </div>
          <div className="tracker">{renderView}</div>
        </section>
      </main>
      <Loading isLoading={loading} />
      <ToastContainer
        toastClassName={(context) =>
          contextClass[context?.type || "default"] + " custom_toaster"
        }
        position="bottom-right"
        bodyClassName={() => "text-sm font-white font-med block p-3"}
      />
      <PopupModal
        open={openModal}
        setOpen={setOpenModal}
        hash={`x454adgsdgdsf4546565656wefdsfdfa4354564565dfgdgdgdgdgdg`}
      />
    </div>
  );
}

export default App;
