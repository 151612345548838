export const StatsState = {
  ONE_HOUR: "1H",
  FULL_DAY: "24H",
};

export const StatsTableTypes = {
  DEPOSIT: 1,
  WITHDRAW: 2,
};

export const MIL = 1000000;

export const ACC_VALUE = "ACC_VALUE";

export enum ButtonType {
  BUY = 1,
  CONNECT = 2,
}

export enum FigureType {
  OLD_ACCOUNT = 1,
  CPC_ACCOUNT = 2,
}

export enum SlidePositionType {
  INIT = 0,
  CPC_ACCOUNT = 1,
  OLD_CPC_ACCOUNT = 2,
  MIXED = 3,
}
