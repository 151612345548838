import React from "react";
import Eagle from "@/assets/images/eagle.png";
import { ReactComponent as OpenSea } from "@/assets/images/opensea.svg";
import { ReactComponent as Logo } from "@/assets/images/Logo.svg";

interface PopupModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  hash: string;
}

const PopupModal: React.FC<PopupModalProps> = ({ open, setOpen, hash }) => {
  return (
    <div
      className={`modal ${open ? "open" : ""}`}
      onClick={() => setOpen(false)}
    >
      <div className="modal-content">
        {/* Close button */}
        <span className="close" onClick={() => setOpen(false)}>
          &times;
        </span>

        {/* Modal content goes here */}
        <p className="logo_modal">
          <img src={Eagle} alt="CPC" />
        </p>
        <h3 className="green">Congratulations !!</h3>
        <p>Chúc mừng bạn đã mint thành công NFT CPC Old Membership.</p>
        <a href={`https://opensea.io/`} className="open_see_button" target="_blank" rel="noreferrer">
          <OpenSea />
          View your NFT on Opensea
        </a>

        <p className="hash">Transaction hash</p>
        <p>{hash}</p>
        <p className="logo_bottom">
          <Logo />
        </p>
      </div>
    </div>
  );
};

export default PopupModal;
